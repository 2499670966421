<template>
  <div>
    <aRow class="haya-panels" :gutter="20">
      <aCol :span="4">
        <div class="box">
          <div class="title"></div>
          <div class="total" v-if="!contract.loading">
            {{ contract.meta.total }}
          </div>
          <div v-else class="total"><a-icon type="loading" /></div>
          <div class="txt">Contratos</div>
          <div class="footer">
            Total Geral: R$
            {{ contract.meta ? contract.meta.total_sold : "0" }}
          </div>
        </div>
      </aCol>

      <aCol :span="5">
        <div class="box">
          <div class="title"></div>
          <div class="total">
            R$
            {{ contract.meta ? contract.meta.total_without_payment_card : "0" }}
          </div>
          <div class="txt">de vendas</div>
          <div class="footer">
            R$
            {{ contract.meta ? contract.meta.total_sold_payment_card : "0" }}
            de carta de crédito
          </div>
        </div>
      </aCol>

      <aCol :span="5">
        <div class="box">
          <div class="title"></div>
          <div class="total">
            <aRow type="flex" justify="space-between">
              <aCol>
                <span @click="openHideProfit()">
                  <a-tooltip v-if="!showProfitData">
                    <template slot="title"> Mostrar lucro </template>
                    <img
                      class="c-pointer"
                      src="@/assets/images/contract/close.png"
                      alt="view"
                      height="16"
                    />
                  </a-tooltip>

                  <a-tooltip v-if="showProfitData">
                    <template slot="title"> Ocultar lucro </template>
                    <img
                      class="c-pointer"
                      src="@/assets/images/contract/visible.png"
                      alt="view"
                      height="16"
                    />
                  </a-tooltip>
                </span>
              </aCol>

              <aCol>
                <div v-if="showProfitData">
                  R$ {{ contract.meta.total_profit }}
                </div>
                <div v-if="showProfitData == false">*************</div>
              </aCol>
            </aRow>
          </div>
          <div class="txt">de lucro</div>
          <div class="footer">
            <div v-if="showProfitData == true">
              {{ contract.meta.percentage_profit }}% de lucro em relação às
              vendas
            </div>
            <div v-if="showProfitData == false">*************</div>
          </div>
        </div>
      </aCol>

      <aCol :span="5">
        <div class="box">
          <div class="title"></div>
          <div class="total">
            R$ {{ contract.meta ? contract.meta.total_taxes : 0 }}
          </div>
          <div class="txt">de taxas</div>
          <div class="footer">
            Custo financeiro: R$
            {{ contract.meta ? contract.meta.total_taxes_finance_cost : 0 }}
          </div>
        </div>
      </aCol>

      <aCol :span="5">
        <div class="box">
          <div class="title"></div>
          <div class="total">
            {{ contract.meta ? contract.meta.total_new_customers : 0 }}
          </div>
          <div class="txt">Novos clientes</div>
          <div class="footer">
            Clientes recorrentes:
            {{ contract.meta ? contract.meta.total_recurring_customers : 2 }}
          </div>
        </div>
      </aCol>
    </aRow>
  </div>
</template>

<script>
export default {
  name: "ContractListValuesPanel",
  props: {
    contract: Object,
  },
  data() {
    return { showProfitData: false };
  },
  methods: {
    openHideProfit() {
      if (this.showProfitData) {
        localStorage.setItem("showContractListProfitData", false);
        this.showProfitData = false;
      } else {
        localStorage.setItem("showContractListProfitData", true);
        this.showProfitData = true;
      }
    },
  },
};
</script>